







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class GridNavigationItem extends Vue {
  @Prop()
  private readonly href!: string;

  @Prop()
  private readonly text!: string;

  @Prop()
  private readonly image!: string;
}
