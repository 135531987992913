

























import { Office } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ProfileCard from './ProfileCard.vue';
import ProfileCarousel from './ProfileCarousel.vue';

@Component({
  components: {
    ProfileCard,
    ProfileCarousel,
  },
})
export default class Profiles extends Vue {
    @Prop()
    private readonly profiles!: Office[];
}
