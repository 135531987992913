













import { Component, Vue, Prop } from 'vue-property-decorator';

import { ImageSourceSet } from 'client-website-ts-library/types';

@Component
export default class ImageBox extends Vue {
  @Prop()
  private readonly image!: string | ImageSourceSet;

  @Prop()
  private readonly href!: string;

  @Prop()
  private readonly external!: boolean;

  @Prop()
  private readonly target!: string;

  @Prop()
  private readonly aspectRatio!: string;
}
