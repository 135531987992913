













































import { Component, Mixins } from 'vue-property-decorator';

import { TestimonialFilter } from 'client-website-ts-library/filters';
import { Testimonial, WebsiteLevel } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';
import Offices from '@/components/Offices.vue';
import Profiles from '@/components/Profiles.vue';
import ImageBox from '@/components/ImageBox.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import GridNavigation from '@/components/GridNavigation.vue';
import GridNavigationItem from '@/components/GridNavigationItem.vue';
import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';
import TestimonialCycler from '@/components/Testimonials/TestimonialCycler.vue';
import AgentCardCycler from '@/components/Cycler/AgentCardCycler.vue';
import LinkButton from '@/components/UI/LinkButton.vue';
import NavigationLink from '@/components/Common/NavigationLink.vue';
import ServicesSlider from '@/components/ServicesSlider.vue';
import Our4StepProcess from '@/components/Our4StepProcess.vue';
import ItemCycler from '@/components/Cycler/ItemCycler.vue';
import { ItemCyclerImageSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';

@Component({
  components: {
    Offices,
    ImageBox,
    Profiles,
    VideoPlayer,
    GridNavigation,
    GridNavigationItem,
    ListingCardCycler,
    TestimonialCycler,
    AgentCardCycler,
    LinkButton,
    NavigationLink,
    ServicesSlider,
    Our4StepProcess,
    ItemCycler,
  },
})
export default class Home extends Mixins(View) {
  private testimonials: Testimonial[] | null = null;

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['webp', 'avif', 'png'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['webp', 'avif', 'png'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['webp', 'avif', 'png'], fallback: 'jpg' }),
  ];

  private testimonialFilter: TestimonialFilter = new TestimonialFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
  });

  loadTestimonials(): void {
    API.Testimonials.Search(this.testimonialFilter).then((testimonials) => {
      this.testimonials = testimonials;
    });
  }
}
