
























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing } from 'client-website-ts-library/types/Listing';

@Component
export default class ListingCard extends Vue {
  @Prop()
  private readonly listing!: Listing;

  get isNew(): boolean {
    const newThreshold = (new Date().getTime()) - 86400 * 7 * 1000;

    return new Date(this.listing.CreateDate).getTime() >= newThreshold;
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing.Address.UnitNumber) {
      parts.push(`${this.listing.Address.UnitNumber}/${this.listing.Address.StreetNumber}`);
    } else {
      parts.push(this.listing.Address.StreetNumber);
    }

    parts.push(this.listing.Address.Address);

    return parts.join(' ');
  }
}
