


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Carousel extends Vue {
  @Prop({ required: true })
  private readonly slides!: number;

  private swipeOffset = 0;

  private index = 0;

  private dragging = false;

  mounted() {
    const track = this.$refs.track as HTMLDivElement;

    track.ontouchstart = (e) => {
      const start = e.touches[0].pageX;
      const startTime = Date.now();

      this.dragging = true;

      let offset = 0;

      document.body.ontouchmove = (mme) => {
        const end = mme.touches[0].pageX;

        offset = start - end;

        this.swipeOffset = offset;
      };

      document.body.ontouchend = () => {
        document.body.ontouchmove = null;
        document.body.ontouchend = null;

        const endTime = Date.now();

        if (this.swipeOffset < 10 && this.swipeOffset > -10) {
          // Treat is as a click
          const tgt = e.target as HTMLElement;

          if (tgt) {
            tgt.click();
          }
        }

        const maxTime = 100;

        const deltaT = Math.min(endTime - startTime, maxTime);

        const speedFactor = Math.min(1.3, Math.max(1, ((maxTime - deltaT) / 110) ** 1.1));

        const itemWidth = track.scrollWidth / this.slides;

        const snap = Math.round(((this.swipeOffset * speedFactor) / itemWidth) * 1.9);

        console.log(snap);

        this.index = Math.max(0, Math.min(this.slides - 1, this.index + snap));
        this.swipeOffset = 0;

        this.dragging = false;
      };
    };

    track.onmousedown = (e) => {
      const start = e.pageX;

      this.dragging = true;

      let offset = 0;

      document.body.onmousemove = (mme) => {
        const end = mme.pageX;

        offset = start - end;

        this.swipeOffset = offset;
      };

      document.body.onmouseup = () => {
        document.body.onmousemove = null;
        document.body.onmouseup = null;

        // eslint-disable-next-line radix
        const itemWidth = track.scrollWidth / this.slides;

        const snap = Math.round(this.swipeOffset / itemWidth);

        this.index = Math.max(0, Math.min(this.slides - 1, this.index + snap));
        this.swipeOffset = 0;

        console.log(snap);

        this.dragging = false;
      };
    };
  }
}
