































import { Component, Vue } from 'vue-property-decorator';

import ItemCycler from '@/components/Cycler/ItemCycler.vue';
import { ItemCyclerImageSlide, ItemCyclerReiszableImageSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';

@Component({
  components: {
    ItemCycler,
  },
})
export default class ServicesSlider extends Vue {
  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  private currentIndex = 0;

  handleIndexChanged(index: number) {
    this.currentIndex = index;
  }
}
